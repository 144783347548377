/* eslint-disable react/jsx-one-expression-per-line */
import React, { PureComponent } from 'react'
import Alert from 'react-s-alert'

import 'react-s-alert/dist/s-alert-default.css'
import 'react-s-alert/dist/s-alert-css-effects/slide.css'
import SEO from '../components/seo'
import { isEmail, encode } from '../components/helpers/UIHelpers'

class B2B extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      activeSection: '',
      activeSubSection: '',
      businessAddress: '',
      businessName: '',
      businessSite: '',
      businessType: '',
      isSendingContactForm: false,
      repEmail: '',
      repName: '',
    }
  }

  submitBusinessForm = e => {
    e.preventDefault()
    const $this = this
    const form = e.target
    $this.setState({
      isSendingContactForm: false,
    })
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    }).then(() => {
      $this.setState({
        isSendingContactForm: false,
      })
      Alert.success(
        'Seu cadastro foi feito com sucesso. Aguarde que dentro de alguns minutos entraremos em contato.',
        {
          effect: 'stackslide',
          position: 'bottom-left',
        }
      )
      setTimeout(() => {
        Alert.closeAll()
        $this.setState({
          activeSection: '',
          activeSubSection: '',
          businessAddress: '',
          businessName: '',
          businessSite: '',
          businessType: '',
          isSendingContactForm: false,
          repEmail: '',
          repName: '',
        })
      }, 3000)
    })
  }

  render() {
    const {
      activeSection,
      activeSubSection,
      businessAddress,
      businessName,
      businessSite,
      businessType,
      isSendingContactForm,
      repEmail,
      repName,
    } = this.state
    return (
      <>
        <SEO title="Programa de Affiliados Immortalis - Para Igrejas, Cemitérios, Funerárias, Floriculturas e Outros negócios" />
        <Alert />
        <form
          name="business"
          hidden
          className="dn"
          data-netlify="true"
          data-netlify-honeypot="validation"
        >
          <input type="text" name="businessAddress" />
          <input type="text" name="businessName" />
          <input type="text" name="businessSite" />
          <input type="text" name="businessType" />
          <input type="text" name="repEmail" />
          <input type="text" name="repName" />
        </form>
        <section className="mw8-ns center pa3">
          <h1 className="f3 f-subheadline-l fw5 mb3 mb4-ns lh-title mt3">
            Immortalis para Igrejas, Cemitérios, Funerárias e Floriculturas
          </h1>
          <p className="lh-copy mb3 mb4-ns f5 f3-l lh-copy">
            Sabemos que são grandes as responsabilidades daqueles que têm de lidar com a morte
            diariamente e tornar esta passagem a mais breve e indolor para seus clientes. Por este
            reconhecimento que oferecemos parcerias com igrejas e empresas que lidam com serviços
            funerários.
          </p>
          <p className="lh-copy f5 mb3">
            Para iniciar, selecione o tipo de empresa ou serviço que você representa:
          </p>
          <div className="flex flex-wrap flex-nowrap-ns">
            <div className="w-100 w-auto-ns pr2-ns mb2">
              <button
                type="button"
                className="btn btn-secondary btn-secondary-outline outline-0 w-100 w-auto-ns"
                onClick={() =>
                  this.setState({
                    activeSection: 'churches',
                    activeSubSection: '',
                    businessType: 'church',
                  })
                }
              >
                Igreja
              </button>
            </div>
            <div className="w-100 w-auto-ns pr2-ns mb2">
              <button
                type="button"
                className="btn btn-secondary btn-secondary-outline outline-0 w-100 w-auto-ns"
                onClick={() =>
                  this.setState({
                    activeSection: 'cemiteries',
                    activeSubSection: '',
                  })
                }
              >
                Cemitério ou Funerária
              </button>
            </div>
            <div className="w-100 w-auto-ns pr2-ns mb2">
              <button
                type="button"
                className="btn btn-secondary btn-secondary-outline outline-0 w-100 w-auto-ns"
                onClick={() =>
                  this.setState({
                    activeSection: 'other',
                    activeSubSection: '',
                    businessType: 'affiliate',
                  })
                }
              >
                Floricultura ou outro serviço
              </button>
            </div>
          </div>
          <div className="mt5-ns">
            {activeSection === 'churches' && (
              <div className="mb5">
                <h2 className="fw5">Para Igrejas</h2>
                <p className="lh-copy">
                  O Immortalis pode servir como o obituário oficial da igreja, lugar em que são
                  listados os membros falecidos, com uma página personalizada, com nome, logotipo e
                  endereço da igreja. Inclusão da carga de dados inicial 100% gratuita e, depois,
                  50% de desconto para a criação de qualquer tributo ou memorial.
                </p>
                <p className="lh-copy">
                  Veja&nbsp;
                  <a
                    href="/igreja-presbiteriana-monte-siao/"
                    target="_blank"
                    className="near-black b"
                  >
                    aqui
                  </a>
                  &nbsp;um exemplo de uma página.
                </p>
                {activeSubSection !== 'church-form' && (
                  <button
                    type="button"
                    className="btn btn-primary mb4"
                    onClick={() => this.setState({ activeSubSection: 'church-form' })}
                  >
                    Cadastrar Igreja Gratuitamente
                  </button>
                )}
                {activeSubSection === 'church-form' && (
                  <form
                    action="/success"
                    name="business"
                    method="post"
                    className="mw6"
                    data-netlify="true"
                    data-netlify-honeypot="validation"
                    onSubmit={this.submitBusinessForm}
                  >
                    <input type="hidden" name="form-name" value="business" />
                    <p className="dn">
                      <label htmlFor="name">
                        Don’t fill this out if you&rsquo;re human:
                        <input id="validation" name="validation" />
                      </label>
                    </p>
                    <div className="mb3 w-100">
                      <div className="input">
                        <input
                          id="businessName"
                          name="businessName"
                          autoComplete="off"
                          value={businessName}
                          onChange={e => this.setState({ businessName: e.target.value })}
                          type="text"
                          className={`${businessName !== '' ? 'filled' : ''}`}
                        />
                        <label htmlFor="businessName">Nome da Igreja</label>
                      </div>
                    </div>
                    <div className="mb3 w-100">
                      <div className="input">
                        <input
                          id="businessAddress"
                          name="businessAddress"
                          autoComplete="off"
                          value={businessAddress}
                          onChange={e => this.setState({ businessAddress: e.target.value })}
                          type="text"
                          className={`${businessAddress !== '' ? 'filled' : ''}`}
                        />
                        <label htmlFor="businessAddress">Endereço da Igreja</label>
                      </div>
                    </div>
                    <div className="mb3 w-100">
                      <div className="input">
                        <input
                          name="repName"
                          id="repName"
                          autoComplete="off"
                          value={repName}
                          onChange={e => this.setState({ repName: e.target.value })}
                          type="text"
                          className={`${repName !== '' ? 'filled' : ''}`}
                        />
                        <label htmlFor="repName">Responsável pela Igreja</label>
                      </div>
                    </div>
                    <div className="mb3 w-100">
                      <div className="input">
                        <input
                          name="repEmail"
                          id="repEmail"
                          autoComplete="off"
                          value={repEmail}
                          onChange={e => this.setState({ repEmail: e.target.value })}
                          type="email"
                          className={`${repEmail !== '' ? 'filled' : ''}`}
                        />
                        <label htmlFor="repEmail">Email do Responsável</label>
                      </div>
                    </div>
                    <div className="mb3 w-100">
                      <div className="input">
                        <input
                          name="businessSite"
                          id="businessSite"
                          autoComplete="off"
                          value={businessSite}
                          onChange={e => this.setState({ businessSite: e.target.value })}
                          type="text"
                          className={`${businessSite !== '' ? 'filled' : ''}`}
                        />
                        <label htmlFor="businessSite">Site da Igreja</label>
                      </div>
                    </div>
                    <div className="mb3 w-100">
                      <button
                        type="submit"
                        className="btn btn-primary w-100"
                        disabled={
                          !isEmail(repEmail) ||
                          businessName.trim() === '' ||
                          businessAddress.trim() === '' ||
                          repName.trim() === '' ||
                          isSendingContactForm
                        }
                      >
                        {isSendingContactForm && <>Cadastrando Igreja...</>}
                        {!isSendingContactForm && <>Cadastrar Igreja</>}
                      </button>
                    </div>
                  </form>
                )}
              </div>
            )}
            {activeSection === 'cemiteries' && (
              <div className="mb5">
                <h2 className="fw5">Para Cemitérios ou Funerárias</h2>
                <p className="lh-copy">
                  Mensalidade de R$250* para gerencimento de obituários, com página personalizada
                  onde são listados os tributos, com nome, logotipo e endereço da funerária.
                </p>
                <p className="lh-copy">
                  Participação automática em nosso programa de afiliados, em que clientes
                  referenciados por sua empresa recebem 20% desconto nos memoriais e sua empresa
                  recebe 50% do valor pago por memorial.
                </p>
                <p className="lh-copy b">
                  * Por tempo limitado, o Immortalis está oferecendo 30 dias de experimentação
                  gratuita para cemitérios e funerárias.
                </p>
                {activeSubSection !== 'cemitery-form' && (
                  <button
                    type="button"
                    className="btn btn-primary mb4"
                    onClick={() => this.setState({ activeSubSection: 'cemitery-form' })}
                  >
                    Testar gratuitamente por 30 dias
                  </button>
                )}
                {activeSubSection === 'cemitery-form' && (
                  <form
                    action="/success"
                    name="business"
                    method="post"
                    className="mw6"
                    data-netlify="true"
                    data-netlify-honeypot="validation"
                    onSubmit={this.submitBusinessForm}
                  >
                    <input type="hidden" name="form-name" value="business" />
                    <p className="dn">
                      <label htmlFor="name">
                        Don’t fill this out if you&rsquo;re human:
                        <input id="validation" name="validation" />
                      </label>
                    </p>
                    <div className="mb3 w-100">
                      <div className="dropdown">
                        <select
                          id="businessType"
                          defaultValue={businessType}
                          className="filled pr4"
                          onChange={e => {
                            this.setState({
                              businessType: e.target.options[e.target.selectedIndex].value,
                            })
                          }}
                        >
                          <option value="" />
                          <option value="cemetery">Cemitério</option>
                          <option value="funeral-home">Funerária</option>
                        </select>
                        <label htmlFor="eventMonth">Tipo de Empresa</label>
                      </div>
                    </div>
                    <div className="mb3 w-100">
                      <div className="input">
                        <input
                          id="businessName"
                          name="businessName"
                          autoComplete="off"
                          value={businessName}
                          onChange={e => this.setState({ businessName: e.target.value })}
                          type="text"
                          className={`${businessName !== '' ? 'filled' : ''}`}
                        />
                        {businessType === 'cemetery' && (
                          <label htmlFor="businessName">Nome do Cemitério</label>
                        )}
                        {businessType === 'funeral-home' && (
                          <label htmlFor="businessName">Nome da Funerária</label>
                        )}
                        {businessType === '' && (
                          <label htmlFor="businessName">Nome da Empresa</label>
                        )}
                      </div>
                    </div>
                    <div className="mb3 w-100">
                      <div className="input">
                        <input
                          id="businessAddress"
                          name="businessAddress"
                          autoComplete="off"
                          value={businessAddress}
                          onChange={e => this.setState({ businessAddress: e.target.value })}
                          type="text"
                          className={`${businessAddress !== '' ? 'filled' : ''}`}
                        />
                        {businessType === 'cemetery' && (
                          <label htmlFor="businessAddress">Endereço do Cemitério</label>
                        )}
                        {businessType === 'funeral-home' && (
                          <label htmlFor="businessAddress">Endereço da Funerária</label>
                        )}
                        {businessType === '' && (
                          <label htmlFor="businessAddress">Endereço da Empresa</label>
                        )}
                      </div>
                    </div>
                    <div className="mb3 w-100">
                      <div className="input">
                        <input
                          name="repName"
                          id="repName"
                          autoComplete="off"
                          value={repName}
                          onChange={e => this.setState({ repName: e.target.value })}
                          type="text"
                          className={`${repName !== '' ? 'filled' : ''}`}
                        />
                        {businessType === 'cemetery' && (
                          <label htmlFor="repName">Responsável pelo Cemitério</label>
                        )}
                        {businessType === 'funeral-home' && (
                          <label htmlFor="repName">Responsável pela Funerária</label>
                        )}
                        {businessType === '' && (
                          <label htmlFor="repName">Responsável pela Empresa</label>
                        )}
                      </div>
                    </div>
                    <div className="mb3 w-100">
                      <div className="input">
                        <input
                          name="repEmail"
                          id="repEmail"
                          autoComplete="off"
                          value={repEmail}
                          onChange={e => this.setState({ repEmail: e.target.value })}
                          type="email"
                          className={`${repEmail !== '' ? 'filled' : ''}`}
                        />
                        <label htmlFor="repEmail">Email do Responsável</label>
                      </div>
                    </div>
                    <div className="mb3 w-100">
                      <div className="input">
                        <input
                          name="businessSite"
                          id="businessSite"
                          autoComplete="off"
                          value={businessSite}
                          onChange={e => this.setState({ businessSite: e.target.value })}
                          type="text"
                          className={`${businessSite !== '' ? 'filled' : ''}`}
                        />
                        {businessType === 'cemetery' && (
                          <label htmlFor="businessSite">Site do Cemitério</label>
                        )}
                        {businessType === 'funeral-home' && (
                          <label htmlFor="businessSite">Site da Funerária</label>
                        )}
                        {businessType === '' && (
                          <label htmlFor="businessSite">Site da Empresa</label>
                        )}
                      </div>
                    </div>
                    <div className="mb3 w-100">
                      <button
                        type="submit"
                        className="btn btn-primary w-100"
                        disabled={
                          !isEmail(repEmail) ||
                          businessType.trim() === '' ||
                          businessName.trim() === '' ||
                          businessAddress.trim() === '' ||
                          repName.trim() === '' ||
                          isSendingContactForm
                        }
                      >
                        {isSendingContactForm && <>Cadastrando Igreja...</>}
                        {!isSendingContactForm && (
                          <span>
                            {businessType === 'cemetery' && <>Cadastrar Cemitério</>}
                            {businessType === 'funeral-home' && <>Cadastrar Funerária</>}
                            {businessType === '' && <>Cadastrar Empresa</>}
                          </span>
                        )}
                      </button>
                    </div>
                  </form>
                )}
              </div>
            )}
            {activeSection === 'other' && (
              <div className="mb5">
                <h2 className="fw5">Para floriculturas ou outros serviços funerários</h2>
                <p className="lh-copy">
                  Caso você possua uma floricultura ou ofereça qualquer produto ou serviço referente
                  ao mercado funerário, o Immortalis oferece o nosso programa de afiliados, em que
                  as pessoas que publicarem um tributo ou memorial, terão 20% de desconto no
                  pagamento e sua empresa recebe 50% do valor pago nos memoriais.
                </p>
                <p className="lh-copy">
                  Por exemplo: um consumidor adquiriu um memorial online no Immortalis e utilizou o
                  código de desconto de sua empresa. Com o custo de R$199,90, o consumidor pagou
                  R$159,92 pelo memorial (20% de desconto). Por utilizar o código de sua empresa,
                  sua empresa receberá R$99,95 por cada memorial vendido.
                </p>
                {activeSubSection !== 'affiliates-form' && (
                  <button
                    type="button"
                    className="btn btn-primary mb4"
                    onClick={() => this.setState({ activeSubSection: 'affiliates-form' })}
                  >
                    Habilitar Programa de Afiliados
                  </button>
                )}
                {activeSubSection === 'affiliates-form' && (
                  <form
                    action="/success"
                    name="business"
                    method="post"
                    className="mw6"
                    data-netlify="true"
                    data-netlify-honeypot="validation"
                    onSubmit={this.submitBusinessForm}
                  >
                    <input type="hidden" name="form-name" value="business" />
                    <p className="dn">
                      <label htmlFor="name">
                        Don’t fill this out if you&rsquo;re human:
                        <input id="validation" name="validation" />
                      </label>
                    </p>
                    <div className="mb3 w-100">
                      <div className="input">
                        <input
                          id="businessName"
                          name="businessName"
                          autoComplete="off"
                          value={businessName}
                          onChange={e => this.setState({ businessName: e.target.value })}
                          type="text"
                          className={`${businessName !== '' ? 'filled' : ''}`}
                        />
                        <label htmlFor="businessName">Nome da Empresa</label>
                      </div>
                    </div>
                    <div className="mb3 w-100">
                      <div className="input">
                        <input
                          name="repName"
                          id="repName"
                          autoComplete="off"
                          value={repName}
                          onChange={e => this.setState({ repName: e.target.value })}
                          type="text"
                          className={`${repName !== '' ? 'filled' : ''}`}
                        />
                        <label htmlFor="repName">Responsável pela Empresa</label>
                      </div>
                    </div>
                    <div className="mb3 w-100">
                      <div className="input">
                        <input
                          name="repEmail"
                          id="repEmail"
                          autoComplete="off"
                          value={repEmail}
                          onChange={e => this.setState({ repEmail: e.target.value })}
                          type="email"
                          className={`${repEmail !== '' ? 'filled' : ''}`}
                        />
                        <label htmlFor="repEmail">Email do Responsável</label>
                      </div>
                    </div>
                    <div className="mb3 w-100">
                      <div className="input">
                        <input
                          name="businessSite"
                          id="businessSite"
                          autoComplete="off"
                          value={businessSite}
                          onChange={e => this.setState({ businessSite: e.target.value })}
                          type="text"
                          className={`${businessSite !== '' ? 'filled' : ''}`}
                        />
                        <label htmlFor="businessSite">Site da Empresa</label>
                      </div>
                    </div>
                    <div className="mb3 w-100">
                      <button
                        type="submit"
                        className="btn btn-primary w-100"
                        disabled={
                          !isEmail(repEmail) ||
                          businessName.trim() === '' ||
                          repName.trim() === '' ||
                          isSendingContactForm
                        }
                      >
                        {isSendingContactForm && <>Cadastrando Igreja...</>}
                        {!isSendingContactForm && <>Habilitar Programa de Afiliados</>}
                      </button>
                    </div>
                  </form>
                )}
              </div>
            )}
          </div>
        </section>
      </>
    )
  }
}

export default B2B
